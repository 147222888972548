import './polyfill';

import PropTypes from 'prop-types';
import React from 'react';
import ReactDom from 'react-dom';

import pkg from '../package.json';

import { SentryErrorBoundary } from './boundary/sentry-error-boundary';
import { Configurable } from './components/configurable';
import Config from './config';
import currentTimeStamp from './utils/datetime/current-timestamp';
import download from './utils/download';
import { createNestedProxy } from './utils/nested-proxy';
import Root, { refreshAppRouter, store } from './';

const WaybackSearchComponent = ({ config }) => (
  <Configurable config={config}>
    <SentryErrorBoundary>
      <Root/>
    </SentryErrorBoundary>
  </Configurable>
);

WaybackSearchComponent.propTypes = {
  config: PropTypes.object
};

WaybackSearchComponent.displayName = 'WaybackSearchComponent';

// Expose app to the global state
window.React = React;
window.ReactDOM = ReactDom;
window.WaybackSearchSaveState = () => download(`wbm-state-${currentTimeStamp()}.json`, JSON.stringify(store.getState()));
window.WaybackSearchComponent = WaybackSearchComponent;
window.WaybackSearchConfig = createNestedProxy(Config, {
  onSet: (target, key, value) => {
    console.info(`Config option "${key}" is set to "${value}"`);
    setTimeout(() => {
      refreshAppRouter();
      console.info('App is refreshed');
    });
  }
});
window.WaybackSearchVersion = pkg.version;
