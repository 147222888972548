import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import entities from './entities';
import ui from './ui';

export default function createRootReducer (history) {
  return combineReducers({
    router: connectRouter(history),
    entities,
    ui
  });
}
