/**
 * redirect to the last available year, if there wasn't explicit selected date
 */

import { put, select, takeEvery } from 'redux-saga/effects';

import { getCapturesOfSubmittedQuery, getSelected } from '../selectors/ui/calendar';

import * as capturesActions from '../reducers/entities/captures';
import { selectCalendarYear } from '../reducers/ui/calendar';

/**
 * get url captures distribution
 *
 * @returns {IterableIterator<*>}
 */
function * newCapturesDistributionReceived () {
  // implicit selection
  const selection = yield select(getSelected);
  if (selection.explicit) {
    return;
  }

  // do we have captures for (1 and more) last years
  const captures = yield select(getCapturesOfSubmittedQuery);

  if (!captures || captures.lastYear >= selection.year) {
    return;
  }

  // update selected year to the last available year
  yield put(selectCalendarYear(captures.lastYear));
}

export function * sagas () {
  yield takeEvery(capturesActions.actionTypes.CAPTURES_DISTRIBUTION_RECEIVED, newCapturesDistributionReceived);
}
