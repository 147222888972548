import { bind } from 'decko';

export default class Queue {
  constructor ({ numOfSimultaneousJobs = 1 } = {}) {
    // queue of open jobs
    this._queueOpen = [];

    // queue of in-progress jobs
    this._queueInProgress = [];

    this._numOfSimultaneousJobs = numOfSimultaneousJobs;
  }

  /**
   * Add lazy job function in a queue.
   * Function should return Promise and solve it once job is done.
   *
   * @param job
   * @returns {Promise}
   */
  @bind
  add (job) {
    return new Promise((resolve, reject) => {
      this._queueOpen.push({
        job,
        resolve,
        reject
      });

      this._checkAvailability();
    });
  }

  @bind
  _checkAvailability () {
    while (this._queueInProgress.length < this._numOfSimultaneousJobs && this._queueOpen.length > 0) {
      const task = this._queueOpen.pop();
      this._queueInProgress.push(task);

      task.job()
        .then(task.resolve, task.reject)
        .then(() => {
          const i = this._queueInProgress.indexOf(task);
          this._queueInProgress.splice(i, 1);
        })
        .then(this._checkAvailability);
    }
  }
}
