import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as calendarSelector from '../selectors/ui/calendar';
import * as searchRequestSelector from '../selectors/ui/search-request';
import { prepareUrl } from '../utils/api-url-processor';
import { getDisplayName } from '../utils/get-display-name';
import { timestampToStr } from '../utils/timestamp-url-parser';

export function redirectIf ({ condition, to }) {
  return (WrappedComponent) => {
    const RedirectIfSearching = (innerProps) => {
      const {
        calendarSelection,
        props,
        state,
        text
      } = innerProps;

      if (condition({ props, state })) {
        let timestamp;

        if (calendarSelection) {
          timestamp = timestampToStr({ equal: calendarSelection });
        } else {
          timestamp = '*';
        }

        return <Redirect to={prepareUrl(to, { text, timestamp })}/>;
      }

      return <WrappedComponent {...innerProps}/>;
    };

    RedirectIfSearching.displayName = `RedirectIfSearching(${getDisplayName(WrappedComponent)})`;

    RedirectIfSearching.propTypes = {
      calendarSelection: PropTypes.object,
      props: PropTypes.object,
      state: PropTypes.object,
      text: PropTypes.string
    };

    return connect(
      (state, props) => ({
        calendarSelection: calendarSelector.getSelected(state, props),
        props,
        state,
        text: searchRequestSelector.getSubmittedQueryText(state, props)
      })
    )(RedirectIfSearching);
  };
}
