import './sparkline-with-captures-range.less';

import { bind } from 'decko';
import PropTypes from 'prop-types';
import React from 'react';
import isEqual from 'lodash/isEqual';

import Config from '../../config';
import { debouncePromise, ignoreDropped } from '../../utils/debounce-promise';

import { CapturesRangeInfo } from '../captures-range-info';
import { ErrorAndRetry, ErrorPanel } from '../error';
import { SpinnerInBlock } from '../spinner';

import SparklineFallback from './sparkline-fallback';

import waitingLazy from '../../hocs/waiting-lazy';

const Sparkline = waitingLazy(
  React.lazy(() => import(/* webpackChunkName: "sparkline" */ './sparkline')),
  SparklineFallback
);

export default class SparklineWithCapturesRange extends React.Component {
  static displayName = 'SparklineWithCapturesRange';

  static propTypes = {
    hasUrlCapturesDistribution: PropTypes.bool,
    highlighted: PropTypes.object,
    selected: PropTypes.object,
    url: PropTypes.string,
    captures: PropTypes.object,
    capturesDistributionInProgress: PropTypes.bool,
    capturesDistributionError: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ]),

    onFetchCapturesDistribution: PropTypes.func.isRequired,
    onHoverDate: PropTypes.func.isRequired,
    onSelectDate: PropTypes.func.isRequired,
    onUnHoverDate: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);
    this._debounceSparkline = debouncePromise(Config.sparkline.debounce_delay);
  }

  @bind
  _fetchCapturesDistribution () {
    const {
      hasUrlCapturesDistribution, capturesDistributionInProgress,
      onFetchCapturesDistribution
    } = this.props;

    if (hasUrlCapturesDistribution || capturesDistributionInProgress) {
      return;
    }

    this._debounceSparkline()
      .then(onFetchCapturesDistribution)
      .catch(ignoreDropped());
  }

  componentDidMount () {
    this._fetchCapturesDistribution();
  }

  componentDidUpdate (prevProp) {
    if (
      prevProp.url !== this.props.url ||
      prevProp.selected !== this.props.selected
    ) {
      this._fetchCapturesDistribution();
    }
  }

  // highlighted objects are considered different when compared via `===` but
  // they are equal by value.
  shouldComponentUpdate (nextProps) {
    return (!isEqual(nextProps.highlighted, this.props.highlighted) ||
            nextProps.captures !== this.props.captures);
  }

  render () {
    const {
      highlighted, selected, url,
      captures, capturesDistributionError,
      capturesDistributionInProgress,
      onUnHoverDate, onHoverDate, onSelectDate
    } = this.props;

    if (capturesDistributionInProgress) {
      return (
        <SpinnerInBlock className="sparkline-with-captures-range"/>
      );
    }

    if (capturesDistributionError) {
      if (typeof capturesDistributionError === 'string') {
        return (
          <ErrorAndRetry
            error={capturesDistributionError}
            onRetry={this._fetchCapturesDistribution}
          />
        );
      }

      if (capturesDistributionError.type === 'blocked') {
        return (
          <ErrorPanel title={'Sorry.'}>
            <p>
              This URL has been excluded from the Wayback Machine.
            </p>
          </ErrorPanel>
        );
      }

      return (
        <ErrorPanel title={'Sorry.'}>
          <p>
            Wayback Machine failed to return archive information.
          </p>
        </ErrorPanel>
      );
    }

    const distribution = captures && captures.distribution;

    return (
      <>
        <CapturesRangeInfo
          first={captures ? captures.firstTimestamp : null}
          last={captures ? captures.lastTimestamp : null}
          total={distribution ? distribution.total : null}
          url={url}
        />
        <Sparkline
          distribution={distribution && distribution.byYear}
          highlightedYear={highlighted.year}
          highlightedMonth={highlighted.month}
          selectedYear={selected.year}
          selectedMonth={selected.month}
          onMouseOut={onUnHoverDate}
          onMouseOverYear={onHoverDate}
          onMouseUpDate={onSelectDate}
        />
      </>
    );
  }
}
