import { all } from 'redux-saga/effects';

import { sagas as redirectToTheLastYearWithCaptures } from './redirect-to-last-year-with-captures';

/**
 * all sagas are here
 *
 * @returns {IterableIterator<*>}
 */
function * rootSaga () {
  console.info('run all sagas');
  yield all([
    redirectToTheLastYearWithCaptures()
  ]);
}

export default rootSaga;
