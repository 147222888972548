/**
 * if query of user is valid url
 *  we will show url detail (calendar, collections, changes and etc)
 * otherwise
 *  it will be just plain search result
 */
import PropTypes from 'prop-types';
import React from 'react';

import { canIUseBetaFeature } from '../../utils/can-i-use-beta-feature';
import waitingLazy from '../../hocs/waiting-lazy';

import CalendarFallback from '../../components/calendar/calendar-fallback';
import SearchResultListFallback from '../../components/search-result/search-result-fallback';

// bundles
const CalendarContainer = waitingLazy(React.lazy(
  () => import(/* webpackChunkName: "calendar" */ '../../containers/calendar-layout')
), CalendarFallback);
const SearchResultContainerV1 = waitingLazy(React.lazy(
  () => import(/* webpackChunkName: "search-result-container-v1" */ '../../components/search-v1')
), SearchResultListFallback);
const SearchResultContainerV2 = waitingLazy(React.lazy(
  () => import(/* webpackChunkName: "search-result-container-v2" */ '../../components/search-result')
), SearchResultListFallback);

export default class SearchResultOrUrlDetails extends React.PureComponent {
  static displayName = 'SearchResultOrUrlDetails';
  static propTypes = {
    hasUrlCapturesDistribution: PropTypes.bool,
    isUrl: PropTypes.bool,
    isUrlValid: PropTypes.bool,
    query: PropTypes.object,
    urlFetchingError: PropTypes.any,

    onRetryIsUrl: PropTypes.func.isRequired
  };

  componentDidMount () {
    if (this.props.urlFetchingError) {
      this.props.onRetryIsUrl();
    }
  }

  componentDidUpdate (prepProp) {
    if (prepProp.query !== this.props.query && this.props.urlFetchingError) {
      this.props.onRetryIsUrl();
    }
  }

  render () {
    const { hasUrlCapturesDistribution, isUrl, isUrlValid } = this.props;

    if (this.props.urlFetchingError) {
      // don't show error notification because AppLayout would show Error and Retry in that case
      return null;
    }

    if (!isUrlValid) {
      // don't show spinner because AppLayout would show spinner
      return null;
    }

    if (isUrl) {
      if (!hasUrlCapturesDistribution && canIUseBetaFeature('url_query') === false) {
        // Prefix queries end with `*`. If this URL is not found and it is a
        // prefix query, we redirect because we need load a WBM UI outside React.
        if (this.props.query.text && this.props.query.text.endsWith('*')) {
          window.location.href = '/web/*/' + this.props.query.text;
        }
        return null;
      }
      return <CalendarContainer/>;
    } else {
      if (canIUseBetaFeature('search') === 'v2') {
        return (
          <div className="search-result-container container">
            <SearchResultContainerV2/>
          </div>
        );
      }

      return (
        <div className="search-result-container container">
          <SearchResultContainerV1/>
        </div>
      );
    }
  }
}
