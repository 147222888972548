import './global-saved-web-pages-count.less';

import React from 'react';

import Config from '../../config';

const GlobalSavedWebPagesCount = () => (
  <div className="global-saved-web-pages-count-boxout">
    Explore more than {Config.num_of_explored_web_pages} <a target="_blank" rel="noopener noreferrer" href={Config.num_of_explored_web_pages_details_url}>web&nbsp;pages</a> saved&nbsp;over&nbsp;time
  </div>
);

export default React.memo(GlobalSavedWebPagesCount);
